import { getQueryStringParam } from '../getQueryStringParam';

export const getHandleAndVariantFromProductLink = (link: string): Record<string, string> => {
	const output = { handle: '', shopify_variant_id: '' };
	const handleRegex = /(\/products\/)((\w|-)*)(\?|\$?)/;
	const matches = link.match(handleRegex);

	if (matches && matches.length) {
		output.handle = matches[2];
		output.shopify_variant_id = getQueryStringParam(link, 'variant');
	}

	return output;
};
