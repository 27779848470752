import { CustomWindow, platformIdentifiers } from '../../../index.d';

declare let window: CustomWindow;

const CONSENT_TRACKING_API = 'consent-tracking-api';

export const getTrackingConsent = (platform: platformIdentifiers): Promise<boolean> => {
	return new Promise((resolve) => {
		if (!window.LittledataLayer.respectUserTrackingConsent) return resolve(false);
		// Temporary fix - no documentation for how to await Shopify object
		if (!window.Shopify) return resolve(false);

		window.Shopify.loadFeatures(
			[
				{
					name: CONSENT_TRACKING_API,
					version: '0.1',
				},
			],
			(error: any) => {
				if (error) return resolve(false);

				return resolve(waitForShopifyConsent(platform));
			},
		);
	});
};

const waitForShopifyConsent = (platform: platformIdentifiers): Promise<boolean> => {
	return new Promise((resolve) => {
		if (window.Shopify.customerPrivacy.userCanBeTracked()) return resolve(true);

		//else listen for tracking consent
		document.addEventListener('trackingConsentAccepted', () => {
			resolve(true);
		});
		// TO DO - ping our server to say the user hasn't opted in yet
		// const clientIdIfNotTracked = 'userNotTracked';
		// setClientID(clientIdIfNotTracked, platform);
	});
};
