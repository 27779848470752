import { getElementsByHref } from '../helpers';

export const getProductLinks = (
	document: Document,
	withImageInChild: boolean,
	givenUrl?: string,
): TimeBombHTMLAnchor[] => {
	// look for /products/ in absolute or relative URLs
	// but not when the URL starts with cdn, or is in query param
	const productUrlRegex = `^(?!\/\/cdn)[-\.:\/,a-z,A-Z,0-9]*\/products\/`;
	const allProductLinks = getElementsByHref(document, givenUrl || productUrlRegex);
	// check if current theme is dawn 2.0 and search for correct dom tree

	let returnProductLinksArray = allProductLinks.filter((anchorTag: HTMLElement) =>
		withImageInChild ? anchorTag.querySelector('img') : anchorTag.parentElement.querySelector('img'),
	);

	if (returnProductLinksArray.length === 0) {
		returnProductLinksArray = allProductLinks.filter((linkElement: HTMLElement) => {
			const anchorElement = linkElement.parentNode.parentNode.parentNode as HTMLBaseElement;

			let shouldTrackListViewed;

			if (withImageInChild) {
				shouldTrackListViewed =
					anchorElement.nextElementSibling?.querySelector('img') ||
					anchorElement.previousElementSibling?.querySelector('img');
			} else {
				shouldTrackListViewed = true;
			}

			return shouldTrackListViewed;
		});
	}

	return returnProductLinksArray;
	// a product link must have an img tag within the parent element to be considered a product view
};

export const impressionHasPrice = (document: Document, impression: Impression): boolean => {
	const { handle, price } = impression;

	if (!handle || !price) return false;
	if (window.LittledataLayer.productListLinksHavePrices !== true) return true;
	const priceInteger = String(Math.round(Number(price)));
	const productUrlRegex = `/products/${handle}`;
	const productLinks = getElementsByHref(document, productUrlRegex);

	// assumption is that the price will be part of a link,
	// but not necessarily the image link
	if (!productLinks.length) return false;

	return productLinks.reduce(
		(memo, element) => memo || element.textContent.search(`${price}|${priceInteger}`) >= 0,
		false,
	);
};
