import { CustomWindow } from '../../index.d';
declare let window: CustomWindow;

export enum HTTPMethod {
	POST = 'POST',
	GET = 'GET',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

export const customTask = (endpoint: string, method: HTTPMethod = HTTPMethod.POST) => {
	return function (customTaskModel: LooseObject): void {
		window._ga_originalSendHitTask = window._ga_originalSendHitTask || customTaskModel.get('sendHitTask');

		customTaskModel.set('sendHitTask', function (sendHitTaskModel: LooseObject) {
			const originalSendHitTask = window._ga_originalSendHitTask;

			try {
				originalSendHitTask(sendHitTaskModel);
				const hitPayload = sendHitTaskModel.get('hitPayload');
				const request = new XMLHttpRequest();

				if (method === HTTPMethod.GET) {
					//put the content of the request as URL params
					request.open(method, endpoint + '?' + hitPayload, true);
					request.send(null);
				} else {
					request.open(method, endpoint, true);
					request.withCredentials = false;
					request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
					console.log('hit payload', hitPayload);
					request.send(hitPayload);
				}
			} catch (err) {
				originalSendHitTask(sendHitTaskModel);
			}
		});
	};
};
