export const productIsVisible = (window: LooseObject, element: LooseObject): boolean => {
	const viewportWidth = window.innerWidth;
	const viewportHeight = window.innerHeight;
	const { top, bottom, left, right, height } = element.getBoundingClientRect();

	if (top < viewportHeight && bottom > 0 && left < viewportWidth && right > 0) {
		let pixelsVisible = height;

		if (top < 0) {
			pixelsVisible += top;
		}
		if (bottom > viewportHeight) {
			pixelsVisible += viewportHeight - bottom;
		}
		const percentVisible = pixelsVisible / height;

		if (percentVisible > 0.8) {
			return true;
		}
	}

	return false;
};
