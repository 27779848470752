/* eslint-env browser */
import { initGtag, trackEvents, sendPageview } from './helpers';
import { pageView, advertiseLD, retrieveAndStoreClientId } from '../common/helpers';
import { getTrackingConsent } from '../common/helpers/getTrackingConsent';
import { retrieveAndStoreGA4Id } from '../segmentTracker/helpers/retrieveAndStoreGA4Id';
import { CustomWindow } from '../../index.d';
import { adblockDetect } from '../common/adblockDetect';

declare let window: CustomWindow;

export const loadGaTracker = (): void => {
	adblockDetect(LittledataLayer.webPropertyId, 'ga');
	getTrackingConsent('google').then(() => {
		initGtag();
		advertiseLD('Google Analytics');
		retrieveAndStoreClientId();
		retrieveAndStoreGA4Id();
		pageView(function () {
			window.ga(function () {
				window.LittledataLayer.isDawnThemeActive = window?.Shopify?.theme?.name === 'Dawn';
				sendPageview();
				trackEvents();
			});
		});
	});
};
