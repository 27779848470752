import { ShopifyProduct } from '../../shopifyProductInterface';

export const convertShopifyProductToVariant = (
	product: ShopifyProduct,
	shopify_variant_id: null | string,
): Impression => {
	let variant = shopify_variant_id && product.variants.find((v) => String(v.id) === shopify_variant_id);

	if (!variant) {
		variant = product.variants[0];
	}
	const { handle, tags } = product;
	const { price, compare_at_price } = variant;
	const output: Impression = {
		id: variant.sku || String(product.id),
		name: product.title,
		price,
		brand: product.vendor,
		category: product.product_type || product.type || 'all', // product_type since June 2021
		variant: variant.title,
		handle,
		shopify_product_id: String(product.id),
		shopify_variant_id: String(variant.id),
		compare_at_price,
		image_url: getFirstImageURL(product.image),
		tags,
	};

	return output;
};

const getFirstImageURL = (image: ShopifyProduct['image']) => {
	if (!image || !image.src) return '';

	return image.src.replace(/^\/\//, 'https://');
};
