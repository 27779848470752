import { CustomWindow } from '../../index.d';
import { httpRequest } from '../common/httpRequest';
import * as ILittledataPixel from './ILittledataPixel';
import { ILittledataEvent } from './ILittledataPixel';
declare let window: CustomWindow;

/**
 * TODOS:
 *   - deal with multiple pixels on st
 */
export class LittledataPixel {
	static TRACKER_URL = 'https://bvl2bpup07.execute-api.us-east-1.amazonaws.com/colibri/fly';
	static settings: ILittledataPixel.Config;
	/**
	 * Initialise library with config
	 * @param settings - the pixel configuration
	 */
	static init(settings: ILittledataPixel.Config) {
		this.settings = settings;
		this.checkSettings();
		window.ldq = window.ldq || [];
		const oldPush = window.ldq.push;

		// @ts-expect-error it's ugly but we have to
		// initialise queue
		window.ldq.push = (event, ...args) => {
			this.sendEvent(event);
			oldPush(...[event, ...args]);
		};

		// flush already loaded events
		this.flushQueue();

		// send pageview
		this.sendEvent({ event_name: 'pageView' });
	}

	/**
	 * Sends event to trackerUrl.
	 * @param event - schema to be defined
	 * @returns
	 */
	static sendEvent(event: ILittledataEvent): Promise<void> {
		LittledataPixel.checkSettings();

		return httpRequest.postJSON<void>(LittledataPixel.TRACKER_URL, {
			settings: this.settings,
			event,
		});
	}

	/**
	 * Flushes queue of events
	 */
	static flushQueue() {
		window.ldq.forEach(this.sendEvent);
	}

	/**
	 * Validates settings are OK to send data
	 */
	static checkSettings() {
		if (!LittledataPixel.settings) {
			throw new Error('LittledataPixel not initialised');
		}
	}
}
