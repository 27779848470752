import { getCookie } from '../../common/getCookie';
import { setClientID } from '../../common/setClientID';
import { CustomWindow } from '../../../index.d';
import { waitForGaToLoad } from '../../common/helpers';

declare let window: CustomWindow;

function parseGA4Cookie(cookieName: string): Promise<string | void> {
	return new Promise((resolve) => {
		function repeat(count: number) {
			if (getCookie(cookieName)) return resolve(getCookie(cookieName).split('.').splice(2, 1).join());
			if (LittledataLayer.debug) {
				if (!count) return resolve(console.error('Timeout reached waiting for cookie to load'));
			}
			setTimeout(repeat.bind(null, count - 1), 50);
		}

		repeat(100); // we are waiting for the cookie for 100 iterations, each of 50ms - total of 5sec
	});
}

export function retrieveAndStoreGA4Id(): void {
	const foundCallback = (): Promise<void> => {
		const mIdParts = window?.LittledataLayer?.measurementId?.split?.('-');

		if (mIdParts) {
			const [, measurementId] = mIdParts;

			return Promise.resolve(parseGA4Cookie(`_ga_${measurementId}`))
				.then((id: string) => {
					return setClientID(id, 'ga4session');
				})
				.catch((error) => console.log(error));
		}
	};

	waitForGaToLoad(foundCallback);
}
