import { httpRequest } from './httpRequest';
import { CustomWindow } from '../../index.d';
import { IDetail } from '../../types';
import { convertShopifyProductToVariant } from './convertShopifyProductToVariant';
import { localStorageNames, shopifyPageTypes } from '../common/constants';

declare let window: CustomWindow;
export const getProductDetail = (): Promise<null | IDetail> => {
	const variantId = findVariantInURL();
	const preV9Detail = window.LittledataLayer.ecommerce.detail;

	if (preV9Detail) {
		const { list_position } = getListNameAndPosition();

		//before snippet v9 the detail was added from Liquid
		return Promise.resolve({
			...getVariantForPreV9Product(preV9Detail, variantId),
			list_position,
		});
	}

	if (window.LittledataLayer.pageType === shopifyPageTypes.product) {
		return fetchProductFromShopify(variantId);
	}

	return Promise.resolve(null);
};

const findVariantInURL = () => {
	// Is the variant ID specified in the URL?
	// variant is a 8 to 20 digit number like 31524084842532
	const matches = window.location.href.match(/[0-9]{8,20}/);

	return matches && matches[0];
};

const getVariantForPreV9Product = (detail: IDetail, variantId: string) => {
	const detailWithVariant = {
		...detail,
	};

	if (variantId) {
		detailWithVariant.shopify_variant_id = variantId;
		//find variant in the list of variants
		const variantList = window.LittledataLayer.ecommerce.variants;

		if (variantList) {
			const variant = variantList.find((obj: LooseObject) => obj.id === variantId);

			if (variant) {
				detailWithVariant.id = variant.sku;
				detailWithVariant.variant = variant.title;
			}
		}
	}

	return detailWithVariant;
};

const fetchProductFromShopify = (variantId: string) => {
	const pathnameMatch = window.location.pathname.match(/\/products\/(.*)/);
	const productPath = pathnameMatch && pathnameMatch[1];

	if (!productPath) return Promise.resolve(null);

	return httpRequest
		.getJSON(`${productPath}.json`)
		.then(({ product }) => {
			const detail = {
				...convertShopifyProductToVariant(product, variantId),
				...getListNameAndPosition(),
			};

			window.LittledataLayer.ecommerce.detail = detail;

			return detail;
		})
		.catch(() => {
			//TO DO - send to Airbrake
			return null;
		});
};

const getListNameAndPosition = () => {
	let position = window.localStorage.getItem(localStorageNames.list_position);

	// TEMPORARY - for continuity with localstorage set by v11.1 and earlier
	if (!position) {
		position = window.localStorage.getItem('position');
	}

	const list_position = position && parseInt(position, 10);
	const list_name = window.localStorage.getItem(localStorageNames.list_name) || '';

	return {
		...(list_position && { list_position }),
		...(list_name && { list_name }),
	};
};
