import { getProductLinks, impressionHasPrice } from './helpers/getProductLinks';
import { convertShopifyProductToVariant } from './convertShopifyProductToVariant';
import { getHandleAndVariantFromProductLink } from './helpers/getHandleAndVariantFromProductLink';
import { productIsVisible } from './helpers/productIsVisible';
import { addClickListener, findProductInArray, findProductIndexInArray } from './addClickListener';
import { httpRequest } from './httpRequest';
import { shopifyPageTypes } from '../common/constants';
import { getProductListName } from './helpers/getProductListName';
require('swiped-events');

type impressionCallback = (impressionTag: Impression[]) => void;

const impressionsToSend = [] as ImpressionToSend[];
const millisecondsForProductAPI = window?.LittledataLayer?.productDelay ?? 2500;

export default (impressionTag: impressionCallback, clickTag: ListClickCallback): void => {
	let waitForScroll = 0;
	const allVariants = [] as Impression[];

	if (!shouldTrackProductLists()) return;
	// data layer versions prior to v9 pre-populated impressions, so wipe those
	LittledataLayer.ecommerce.impressions = [];

	function trackImpressions() {
		getProductLinks(document, LittledataLayer.productListLinksHaveImages).forEach((element, index) => {
			const { handle, shopify_variant_id } = getHandleAndVariantFromProductLink(element.href);

			if (productAlreadyViewed(handle, shopify_variant_id)) return;
			if (productIsVisible(window, element)) {
				//prevent product view from triggering again while sending is in progress
				impressionsToSend.push({
					handle,
					shopify_variant_id,
					list_position: index + 1,
				});
				addClickListener(element, clickTag);
			}
		});

		if (impressionsToSend.length > 0) {
			debugModeLog(impressionsToSend, false);
			getVariantsFromShopify(impressionsToSend, impressionTag, allVariants);
			window.setTimeout(() => {
				// send all products fetched within one second
				const variantsReadyToSend = impressionsToSend
					.map((impression) => {
						const previouslyFetched = findProductInArray(
							allVariants,
							impression.handle,
							impression.shopify_variant_id,
						);

						return {
							...previouslyFetched,
							list_position: impression.list_position,
							list_name: getProductListName(),
						};
					})
					.filter((impression: Impression) => impressionHasPrice(document, impression));

				fireImpressionTag(variantsReadyToSend, impressionTag);
			}, millisecondsForProductAPI);
		}
	}

	window.setTimeout(function () {
		clearTimeout(waitForScroll);
		trackImpressions();
	}, 500); /* wait for user to see the products above the fold */

	document.addEventListener('scroll', () => {
		//assumes that people need 200ms after scrolling stops to register an impression
		clearTimeout(waitForScroll);
		waitForScroll = window.setTimeout(function () {
			trackImpressions();
		}, 200);
	});

	document.addEventListener('swiped', () => {
		//uses swiped-events pacakge
		//assumes that swipe may trigger a carousel, taking time to render
		clearTimeout(waitForScroll);
		waitForScroll = window.setTimeout(function () {
			trackImpressions();
		}, 300);
	});
};

const fireImpressionTag = (newImpressions: Impression[], impressionTag: impressionCallback) => {
	if (!newImpressions.length) return;
	LittledataLayer.ecommerce.impressions = [...LittledataLayer.ecommerce.impressions, ...newImpressions];
	newImpressions.forEach((v) => {
		const index = findProductIndexInArray(impressionsToSend, v.handle, v.shopify_variant_id);

		impressionsToSend.splice(index, 1);
	});
	debugModeLog(newImpressions, true);
	impressionTag(newImpressions);
};

export const getVariantsFromShopify = (
	impressions: ImpressionToSend[],
	impressionTag: CallableFunction,
	allVariants: Impression[],
): void => {
	const impressionsNotFetchedPreviously = impressions
		.map((impression) => {
			const previouslyFetched = findProductInArray(allVariants, impression.handle, impression.shopify_variant_id);

			return previouslyFetched ? null : impression;
		})
		.filter((impression) => impression);
	const handleGroups = groupBy(impressionsNotFetchedPreviously, 'handle');

	Object.keys(handleGroups).forEach((handle) =>
		httpRequest
			.getJSON(`/products/${handle}.json`)
			.then((json: any) => {
				json.product.variants.forEach((variant: LooseObject) => {
					const shopify_variant_id = String(variant.id);

					if (findProductInArray(allVariants, json.product.handle, shopify_variant_id)) return;
					allVariants.push(convertShopifyProductToVariant(json.product, shopify_variant_id));
				});
			})
			.catch((ex) => {
				console.debug('Littledata unable to fetch', handle, ex);
			}),
	);
};

export const productAlreadyViewed = (handle: string, shopify_variant_id: string): Impression =>
	findProductInArray(LittledataLayer.ecommerce.impressions, handle, shopify_variant_id) ||
	findProductInArray(impressionsToSend, handle, shopify_variant_id);

const groupBy = (givenArray: any[], key: string) => {
	return givenArray.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);

		return rv;
	}, {});
};
const debugModeLog = (variants: any[], afterDelay: boolean) => {
	if (LittledataLayer.debug === true) {
		if (variants.length) {
			const handleArray = variants.map((v) => `${v.handle} (${v.shopify_variant_id})`);

			if (afterDelay) {
				console.log(
					`Littledata script triggered view events after ${millisecondsForProductAPI}ms`,
					handleArray,
				);
			} else {
				console.log(`Littledata script found product links`, handleArray);
			}
		}
		if (afterDelay && impressionsToSend.length) {
			const toSendArray = impressionsToSend.map((v) => `${v.handle} (${v.shopify_variant_id})`);

			console.warn(
				`Littledata still waiting for these product details after ${millisecondsForProductAPI}ms:`,
				toSendArray,
			);
		}
	}
};

function shouldTrackProductLists() {
	const hasLegacyProductImpressions =
		LittledataLayer.ecommerce.impressions && LittledataLayer.ecommerce.impressions.length;
	const isCollectionPage =
		LittledataLayer.pageType &&
		[shopifyPageTypes.collection, shopifyPageTypes.search].includes(LittledataLayer.pageType);

	return hasLegacyProductImpressions || isCollectionPage || LittledataLayer.productListsOnAnyPage;
}
