import { GAds } from '../../index.d';
import { AW_CONVERSION_REGEX } from './constants';

/**
 * Sends a pageview to Google Ads - only if AW ID is a valid one
 * @param {string[]} adsIds array of strings (AW IDs and/or labels) from ld-news or LittledataLayer properties
 * @returns gtag config (Google Ads pageview event)
 */
export const sendAdsPageview = (adsIds: string[]): LooseObject | undefined => {
	adsIds.forEach((id) => {
		if (parseAWId(id)) return gtag('config', parseAWId(id));
	});

	return;
};

/**
 * Parsing string to get only valid AW ID
 * @param {string} fullId id and label (eg.'AW-123/dlkfajdlsj'), or only id (eg.'AW-123')
 * @returns {GAds.awId|undefined} id as a type GAds.awId or undefined
 */
const parseAWId = (fullId: string): GAds.awId | undefined => {
	const awId = fullId.includes('/') ? fullId.split('/')[0] : fullId;

	if (validateAWId(awId)) return `AW-${validateAWId(awId)}` as GAds.awId;

	return;
};

/**
 * Checking if ID contains 'AW-' prefix or outputting a console error
 * @param {string} id is the first part of the single ads ID string
 * @returns {number|undefined} numeric part of the AW ID or undefined
 */
const validateAWId = (id: string): number | undefined => {
	if (id.includes('AW-')) return +id.split('-')[1];

	console.error(`${id} is a wrong format of Google Ads Id. Pageview cannot be sent. Please begin with "AW-"`);

	return;
};

/**
 * Sending Ads transaction conversion event on the thank_you page - only if the AW ID contains the label
 * @param {string[]} adsIds array of IDs (passed from ld-news)
 * @param {number} value transaction value, should be integer
 * @param {string} currency window.Shopify.checkout.currency
 * @returns gtag conversion event or undefined
 */
export const sendAdsTransactionConversion = (
	adsIds: string[],
	value: number,
	currency: string,
): LooseObject | undefined => {
	adsIds.forEach((id) => {
		if (checkFullFormat(id)) {
			return gtag('event', 'conversion', {
				...prepareAdsConversion(checkFullFormat(id), value, currency),
			});
		}
	});

	return;
};

/**
 * Checks if the AW ID contains label and is in the right format
 * @param {string} fullId AW ID and label (eg.'AW-123/dlkfajdlsj')
 * @returns correct AW conversion ID and label or undefined
 */
const checkFullFormat = (fullId: string): GAds.fullAwId | undefined => {
	const awReg = new RegExp(AW_CONVERSION_REGEX);

	if (awReg.test(fullId)) {
		return `${parseAWId(fullId)}/${fullId.split('/')[1]}` as GAds.fullAwId;
	}

	console.error(`${fullId} ID doesn't contain a Google Ads conversion label. Conversion event was not sent`);

	return;
};

/**
 * Gives the correct format of the Ads conversion config object
 * @param {GAds.fullAwId} send_to AW ID and label (eg.'AW-123/dlkfajdlsj')
 * @param {number} value transaction value, integer
 * @param {string} currency transaction currency
 * @returns conversion config object
 */
export const prepareAdsConversion = (send_to: GAds.fullAwId, value: number, currency: string): GAds.Conversion => {
	return {
		send_to,
		value,
		currency,
	};
};
