import { getHandleAndVariantFromProductLink } from './helpers/getHandleAndVariantFromProductLink';
import { getProductLinks, impressionHasPrice } from './helpers/getProductLinks';
import { localStorageNames } from './constants';

export const addClickListener = (singleElement: TimeBombHTMLAnchor, clickTag: ListClickCallback): void => {
	if (LittledataLayer.productClicks === false) return;
	//there may be other links to the same product on the page that we want to track
	getProductLinks(document, false, singleElement.href).forEach((element) => {
		addEventListenerToProductLink(element, clickTag);
	});
};

export const listClickCallback = (element: TimeBombHTMLAnchor, openInNewTab: boolean): any => {
	window.clearTimeout(element.timeout);
	if (openInNewTab) return window.open(element.href, '_blank');
	document.location.href = element.href;
};

export const findProductInArray = (array: any[], handle: string, shopify_variant_id: string): Impression =>
	array.find((p) => {
		return shopify_variant_id
			? handle === p.handle && p.shopify_variant_id === shopify_variant_id
			: handle === p.handle;
	});

export const findProductIndexInArray = (array: any[], handle: string, shopify_variant_id: string): number =>
	array.findIndex((p) => {
		return shopify_variant_id
			? handle === p.handle && p.shopify_variant_id === shopify_variant_id
			: handle === p.handle;
	});

const debugModeLog = (product: Impression) => {
	if (LittledataLayer.debug === true) {
		console.log(`Littledata tracking click on product ${product.handle} (${product.shopify_variant_id})`);
	}
};
const addEventListenerToProductLink = (element: TimeBombHTMLAnchor, clickTag: ListClickCallback): void => {
	element.addEventListener('click', function (ev) {
		const { handle, shopify_variant_id } = getHandleAndVariantFromProductLink(element.href);
		// assumes that by now ecommerce.impressions is populated
		const product = findProductInArray(LittledataLayer.ecommerce.impressions, handle, shopify_variant_id);

		if (!product || !impressionHasPrice(document, product)) return;

		window.localStorage.setItem(localStorageNames.list_position, String(product.list_position));
		window.localStorage.setItem(localStorageNames.list_name, String(product.list_name));
		debugModeLog(product);
		const openInNewTab = ev.ctrlKey || ev.metaKey;

		ev.preventDefault();
		/* wait a maximum of 1 second before redirecting */
		element.timeout = window.setTimeout(function () {
			listClickCallback(element, openInNewTab);
		}, 1000);
		clickTag(product, element, openInNewTab);
	});
};
