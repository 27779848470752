import { CustomWindow } from '../../index.d';
import { OwnLayer } from '../../types';
import UrlChangeTracker from './UrlChangeTracker';
import { customTask, HTTPMethod } from '../gaTracker/customTask';
import { getValidGAClientId } from './getCookie';
import { setClientID } from './setClientID';
import { LittledataScriptVersion } from './constants';

declare let window: CustomWindow;
export declare type CheckCallback = () => any;
export declare type FoundCallback = (err: Error, data?: any) => void;

/**
 *
 * @param fireTag - callback to call when willing to fire pageviews
 */
export const pageView = (fireTag: () => void): void => {
	if (document.hidden === true) {
		// delay page firing until the page is visible
		let triggeredPageView = false;

		document.addEventListener('visibilitychange', function () {
			if (!document.hidden && !triggeredPageView) {
				fireTag();
				triggeredPageView = true;
			}
		});
	} else if (document.readyState === 'loading') {
		//delay until DOM is ready
		document.addEventListener('DOMContentLoaded', function () {
			fireTag();
		});
	} else {
		fireTag();
	}

	// now listen for changes of URL on product and other pages
	// Shopify uses history.replaceState() when variant changes
	if (LittledataLayer.doNotTrackReplaceState !== true) {
		const urlChangeTracker = new UrlChangeTracker(true);

		urlChangeTracker.setCallback(fireTag);
	}
};

export const getElementsByHref = (document: Document, regex: RegExp | string): TimeBombHTMLAnchor[] => {
	const htmlCollection = document.getElementsByTagName('a');
	const r = new RegExp(regex);

	return Array.prototype.slice
		.call(htmlCollection)
		.filter(
			(element: HTMLAnchorElement) =>
				element.href && !element.className.includes('visually-hidden') && r.test(element.href),
		);
};

export const trackProductImageClicks = (clickTag: (image: HTMLImageElement) => void): void => {
	if (LittledataLayer.productPageClicks === false) return;
	getElementsByHref(document, '^https://cdn.shopify.com/s/files/.*/products/').forEach((element) => {
		element.addEventListener('click', function () {
			// only add event to product images
			const image = this.getElementsByTagName('img')[0];

			if (!image) return false;
			clickTag(image);
		});
	});
};

export const trackSocialShares = (clickTag: (name?: string) => void): void => {
	if (LittledataLayer.productPageClicks === false) return;
	const networks = '(facebook|pinterest|twitter|linkedin|plus.google|instagram)';

	getElementsByHref(document, `${networks}\.com/(share|pin|intent)`).forEach((element) => {
		element.addEventListener('click', function () {
			const match = this.href.match(new RegExp(networks));

			clickTag(match && match[0]);
		});
	});
};

/**
 * Waits for LittledataLayer to load, otherwise if bundledSettings are present will set them as LittledataLayer
 * If neither LittledataLayer nor bundledSettings are present will throw an error
 * If both LittledataLayer and bundledSettings are present, the options present in bundledSettings will overwrite those in LittledataLayer
 * @param bundledSettings - partial LittledataLayer options
 * @param checkTimeout - seconds to wait for LittledataLayer
 */
export const ensureLittedataLayerWithBundledSettings = async (
	bundledSettings: Partial<OwnLayer> = {},
	checkTimeout = 5000,
): Promise<void> => {
	window.LittledataScriptVersion = LittledataScriptVersion;
	const checkLittedataLayer = () => window.LittledataLayer != null;

	await waitForObjectToLoadP(checkLittedataLayer, checkTimeout);

	if (!window.LittledataLayer && Object.keys(bundledSettings).length === 0) {
		throw new Error('Aborting Littledata tracking as LittledataLayer was not found - settings are missing');
	}

	if (!window.LittledataLayer) {
		window.LittledataLayer = bundledSettings as OwnLayer;
	}

	window.LittledataLayer = {
		...window.LittledataLayer,
		...bundledSettings,
	};
};

export const advertiseLD = (app: string): void => {
	if (!LittledataLayer.hideBranding) {
		const appURI = app === 'Segment' ? 'segment-com-by-littledata' : 'littledata';

		console.log(
			`%c\nThis store uses Littledata 🚀 to automate its ${app} setup and make better, data-driven decisions. Learn more at http://apps.shopify.com/${appURI} \n`,
			'color: #088f87;',
		);
	}
};

export function retrieveAndStoreClientId(): void {
	waitForGaToLoad();
}

export const setCustomTask = (tracker: LooseObject): void => {
	const MPEndpointLength = LittledataLayer.MPEndpoint && LittledataLayer.MPEndpoint.length;

	if (MPEndpointLength) {
		tracker.set('customTask', customTask(LittledataLayer.MPEndpoint, HTTPMethod.GET));
	}
};

export const documentReady = (callback: CallableFunction): void => {
	// see if DOM is already available
	if (document.readyState === 'complete' || document.readyState === 'interactive') {
		// call on next available tick
		setTimeout(callback, 1);
	} else {
		//@ts-ignore
		document.addEventListener('DOMContentLoaded', callback);
	}
};

export const hasGaTrackers: CheckCallback = (): unknown[] | boolean => {
	const trackers = window?.ga?.getAll?.();

	return trackers?.length ? trackers : false;
};

/**
 * Waits for the Google Analytics object to be loaded
 * The checkCallback function checks if ga trackers are available as a
 * condition for moving forward, while the foundCallback function will execute if that condition was true.
 * If a callback is provided, that callback will be executed, otherwise the google clientID will be saved
 * from trackers.
 * @param callback - Optional callback that will be executed on GA load.
 */
export const waitForGaToLoad = (callback?: CallableFunction): void => {
	const foundCallback: FoundCallback = (err: Error, trackers: unknown[]): void => {
		if (err) {
			if (LittledataLayer.debug) {
				console.error(err);
			}

			return;
		}

		if (callback) return callback();
		setCustomTask(trackers[0]);

		return setClientID(getGAClientId(trackers[0]), 'google');
	};

	waitForObjectToLoad(hasGaTrackers, foundCallback);
};

/**
 * Waits for an object to be loaded using a check callback and a found callback.
 * The check callback should be synchronous and should return a truthy value in order to proceed.
 * If the value is falsy, we schedule another check in deferTime ms.
 * If the value is truthy, we call foundCb with the result.
 * If the timeout was reached we call foundCb with an error
 * @param checkCb - sync function that returns a value we need to exist
 * @param foundCb - error-first callback that will be called when value exists
 * @param startTime - defaults to current timestamp, needed to check for timeout
 * @param deferTime - defaults to 50, needed to schedule another check
 * @param timeout - defaults to 10 mins, needed to check for timeout
 */
export const waitForObjectToLoad = (
	checkCb: CheckCallback,
	foundCb: FoundCallback,
	startTime: number = new Date().getTime(),
	deferTime = 50,
	timeout = 60000,
): void => {
	const checkResult = checkCb(); // Sync function

	if (!checkResult) {
		// If check function returns falsy, schedule another check in deferTime ms
		if (new Date().getTime() - startTime > timeout) {
			return foundCb(new Error('Timeout reached waiting for object to load'));
		}

		setTimeout(() => waitForObjectToLoad(checkCb, foundCb, startTime, deferTime, timeout), deferTime);

		return;
	}

	foundCb(null, checkResult);
};

/**
 * Promisified version of waitForObjectToLoad
 * Resolves with true if the checkCb returns true, otherwise will resolve with false after timeout is reached.
 * @param checkCb - sync function that returns a value we need to exist
 * @param timeout - defaults to 10 mins, needed to check for timeout
 * @param startTime  - defaults to current timestamp, needed to check for timeout
 * @param deferTime - defaults to 50, needed to schedule another check
 */
export const waitForObjectToLoadP = (
	checkCb: CheckCallback,
	timeout?: number,
	startTime?: number,
	deferTime?: number,
): Promise<boolean> => {
	return new Promise((resolve) => {
		const foundCb = (err: Error, result: boolean) => {
			if (err) {
				resolve(false);
			}

			resolve(result);
		};

		waitForObjectToLoad(checkCb, foundCb, startTime, deferTime, timeout);
	});
};

export const getGAClientId = (tracker: LooseObject): string => {
	const clientId = tracker.get('clientId');

	return getValidGAClientId(clientId) ? clientId : '';
};
