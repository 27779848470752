import { CustomWindow, GA4Product } from '../../index.d';
import { IDetail } from '../../types';
import { removePii, cleanPageviewUrl } from '../common/removePii';
import { trackProductImageClicks, trackSocialShares, getGAClientId } from '../common/helpers';
import { getProductDetail } from '../common/getProductDetail';
import productListViews from '../common/productListViews';
import { listClickCallback } from '../common/addClickListener';
import getConfig from '../common/getConfig';
import { getActiveCurrency } from '../common/helpers/getActiveCurrency';
import { sendAdsPageview } from '../common/sendToGAds';
import { hashString } from '../common/helpers/hashString';
import { LittledataPixel } from '../littledataPixel/LittledataPixel';

declare let window: CustomWindow;

const event_category = 'Shopify (Littledata)';

const getWebPropertyId = () => LittledataLayer.webPropertyId || LittledataLayer.webPropertyID; // spelling prior to LittledataLayer v9.0.1
const getGA4Id = () => LittledataLayer.measurementId;

export const initGtag = (): void => {
	window.dataLayer = window.dataLayer || [];
	const stubFunction = function () {
		// eslint-disable-next-line prefer-rest-params
		dataLayer.push(arguments);
	};

	window.gtag = window.gtag || stubFunction;

	window.ga =
		window.ga ||
		function () {
			// ga expects "arguments" variable
			// eslint-disable-next-line prefer-rest-params
			(window.ga.q = window.ga.q || []).push(arguments);
		};
	window.ga.l = +new Date();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	gtag('js', new Date());

	if (hasGA3()) {
		gtag('config', getWebPropertyId(), {
			...getConfig('GA3'),
			send_page_view: false,
		});
	}
	if (hasGA4()) {
		gtag('config', getGA4Id(), {
			...getConfig(),
			send_page_view: false,
		});
	}
};

export const ga4EventParams = (): Record<string, string | boolean> => {
	const trackers = window.ga?.getAll?.();
	const gaTracker = trackers && trackers.length && trackers[0];
	const littledata_client_id = gaTracker ? getGAClientId(gaTracker) : '';

	return {
		littledata_client_id,
		sent_from: 'Littledata script',
		send_to: LittledataLayer.measurementId,
		...(LittledataLayer.debug && { debug_mode: LittledataLayer.debug }),
	};
};

export const sendPageview = (): void => {
	const page_title = removePii(document.title);
	const locationWithMedium = addUTMMediumIfMissing(document.location.href);
	const page_location = cleanPageviewUrl(locationWithMedium);

	dataLayer.push({
		event: 'pageview',
		page_title,
		page_location,
		...(LittledataLayer.customer?.email && { email: hashString(LittledataLayer.customer.email) }),
	});

	// sending Google Ads pageview, if AW Id is set
	if (LittledataLayer.googleAdsConversionIds?.length > 0) sendAdsPageview(LittledataLayer.googleAdsConversionIds);

	getProductDetail().then((product: IDetail) => {
		if (product) {
			sendViewItemEvent(product);
		}
	});

	if (LittledataLayer.disablePageviews) {
		if (LittledataLayer.debug) {
			console.log('Skipped GA pageview for', page_location);
		}

		return;
	}
	if (hasGA3() && hasGA4()) {
		gtag('event', 'page_view', {
			page_title,
			page_location,
			send_to: getWebPropertyId(),
		});
		gtag('event', 'page_view', {
			page_title,
			page_location,
			...ga4EventParams(),
		});
	}
	// this is a preparation for when GA3 is not required for GA4 to run
	/* 	if (hasGA4() && !hasGA3()) {
		gtag('event', 'page_view', {
			page_title,
			page_location,
			...ga4EventParams(),
		});
	} */
	if (hasGA3() && !hasGA4()) {
		gtag('event', 'page_view', {
			page_title,
			page_location,
			send_to: getWebPropertyId(),
		});
	}
};

export const trackEvents = (): void => {
	if (LittledataLayer.disableClientSideEvents) {
		if (LittledataLayer.debug) {
			console.log('Skipping all event tracking');
		}

		return;
	}
	const clickTag = (product: Impression, element: TimeBombHTMLAnchor, openInNewTab: boolean) => {
		sendSelectContentEvent(product, element, openInNewTab);
	};

	productListViews((products: Impression[]) => {
		sendViewItemListEvent(products);
	}, clickTag);

	getProductDetail().then((product: IDetail) => {
		if (product) {
			// if PDP, we can also track clicks on images and social shares
			trackProductImageClicks((image) => {
				dataLayer.push({
					event: 'product_image_click',
					name: image.id,
					image_url: image.src,
					...(LittledataLayer.customer?.email && { email: hashString(LittledataLayer.customer.email) }),
				});

				if (hasGA4()) {
					gtag('event', 'select_content', {
						...ga4EventParams(),
						content_type: 'product',
						item_product_id: product.shopify_product_id,
						item_sku: product.id,
						item_variant_id: product.shopify_variant_id,
						image_url: image.src,
					});
				}
				if (hasGA3()) {
					gtag('event', 'Product image click', {
						event_category,
						event_label: image.id,
						send_to: getWebPropertyId(),
					});
					try {
						LittledataPixel.sendEvent({
							event_destination: 'ga3',
							event_name: 'Product image click',
							event_category,
							event_label: image.id,
							send_to: getWebPropertyId(),
						});
					} catch (ex) {}
				}
			});

			trackSocialShares((network) => {
				dataLayer.push({
					event: 'share_product',
					network,
					...(LittledataLayer.customer?.email && { email: hashString(LittledataLayer.customer.email) }),
				});

				if (hasGA4()) {
					gtag('event', 'share', {
						...ga4EventParams(),
						method: network,
					});
				}
				if (hasGA3()) {
					gtag('event', 'Social share', {
						event_category,
						event_label: network,
						send_to: getWebPropertyId(),
					});
					try {
						LittledataPixel.sendEvent({
							event_destination: 'ga3',
							event_name: 'Social share',
							event_category,
							event_label: network,
							send_to: getWebPropertyId(),
						});
					} catch (ex) {}
				}
			});
		}
	});
};

export const filterGAProductFields = (product: LooseObject): LooseObject => {
	//pick only the allowed fields from GA EE specification
	//https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
	const gaProductFields = [
		'name',
		'id',
		'price',
		'brand',
		'category',
		'variant',
		'list',
		'list_name',
		'list_position',
	];
	const gaProduct: LooseObject = {};

	gaProductFields.forEach((field) => {
		if (product[field]) gaProduct[field] = product[field];
	});

	return gaProduct;
};

const addUTMMediumIfMissing = (url: string) => {
	const utmMedium = /(\?|&)utm_medium=/;
	const utmSource = /utm_source=[a-z,A-Z,0-9,-,_]+/;
	const sourceMatches = url.match(utmSource);

	if (!sourceMatches || !sourceMatches.length || utmMedium.test(url)) {
		return url;
	}
	// Shopify adds a utm_source tag for it's own tracking, without specifying utm_medium
	// we add 'referral' to ensure it shows up in GA
	const sourceTag = sourceMatches[0];
	const utmTags = sourceTag + '&utm_medium=referral';

	return url.replace(sourceTag, utmTags);
};

function sendViewItemListEvent(products: Impression[]): void {
	if (hasGA4()) {
		const listName = (products && products[0]?.list_name) || '';

		gtag('event', 'view_item_list', {
			...ga4EventParams(),
			currency: getActiveCurrency(),
			items: convertProductsToGa4Format(products, true),
			item_list_name: listName,
			item_list_id: listName,
		});
	}

	if (hasGA3()) {
		const gaProducts = products.map((product) => filterGAProductFields(product));

		gtag('event', 'view_item_list', {
			event_category,
			items: gaProducts,
			send_to: getWebPropertyId(),
			non_interaction: true,
		});
		try {
			LittledataPixel.sendEvent({
				event_destination: 'ga3',
				event_name: 'view_item_list',
				event_category,
				send_to: getWebPropertyId(),
			});
		} catch (ex) {}
	}

	dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			impressions: products,
		},
		...(LittledataLayer.customer?.email && { email: hashString(LittledataLayer.customer.email) }),
	});
}

function sendViewItemEvent(product: IDetail): void {
	if (hasGA4()) {
		gtag('event', 'view_item', {
			...ga4EventParams(),
			currency: getActiveCurrency(),
			value: <number>+product.price,
			items: convertProductsToGa4Format(new Array(product), false),
		});
	}
	if (hasGA3()) {
		gtag('event', 'view_item', {
			event_category,
			items: [filterGAProductFields(product)],
			non_interaction: true,
			send_to: getWebPropertyId(),
		});
		try {
			LittledataPixel.sendEvent({
				event_destination: 'ga3',
				event_name: 'view_item',
				event_category,
				send_to: getWebPropertyId(),
			});
		} catch (ex) {}
	}

	dataLayer.push({
		event: 'view_item',
		ecommerce: {
			detail: {
				actionField: { list: product.list_name },
				products: [product],
			},
		},
		...(LittledataLayer.customer?.email && { email: hashString(LittledataLayer.customer.email) }),
	});
}

function sendSelectContentEvent(product: IDetail, self: TimeBombHTMLAnchor, openInNewTab: boolean): void {
	dataLayer.push({
		event: 'select_content',
		ecommerce: {
			click: {
				actionField: { list: product.list_name },
				products: [product],
			},
		},
	});

	if (hasGA4()) {
		gtag('event', 'select_item', {
			...ga4EventParams(),
			currency: getActiveCurrency(),
			items: convertProductsToGa4Format(new Array(product), true),
			event_callback: listClickCallback(self, openInNewTab),
		});
	}
	if (hasGA3()) {
		gtag('event', 'select_content', {
			event_category,
			content_type: 'product',
			items: [filterGAProductFields(product)],
			send_to: getWebPropertyId(),
			event_callback: listClickCallback(self, openInNewTab),
		});
		try {
			LittledataPixel.sendEvent({
				event_destination: 'ga3',
				event_name: 'select_content',
				event_category,
				send_to: getWebPropertyId(),
			});
		} catch (ex) {}
	}
}

export const hasGA4 = (): boolean => {
	return LittledataLayer.measurementId !== undefined;
};

export const hasGA3 = (): boolean => !!getWebPropertyId();

function convertProductsToGa4Format(products: IDetail[], sendIndex: boolean): GA4Product[] {
	return products.map((product) => {
		let price: number = +product.compare_at_price;
		const discountedPrice: number = +product.price;

		if (price === 0) price = discountedPrice;
		const discount: number = price - discountedPrice;
		const converted = {
			item_id: product.shopify_product_id,
			item_name: product.name,
			item_brand: product.brand,
			item_category: product.category,
			item_variant: product.variant,
			item_sku: product.id,
			item_variant_id: product.shopify_variant_id,
			price,
			index: product.list_position,
			discount: parseFloat(discount.toFixed(2)),
			quantity: 1,
		};

		if (!sendIndex) {
			delete converted.index;
		}

		return converted;
	});
}
