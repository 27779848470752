import { LittledataPixel } from '../littledataPixel/LittledataPixel';

/**
 * Detects if there's an adblock blocking gtag
 */
export function adblockDetect(pixel: string, destination: string): Promise<any | void> {
	const adblocked: string | undefined = window.localStorage.getItem('colibrius_adblocked');

	LittledataPixel.init({ pixelId: pixel });

	return fetch('https://www.googletagmanager.com/gtag/js?id=UA-1337-1')
		.then(() => {
			if (adblocked !== 'false') {
				window.localStorage.setItem('colibrius_adblocked', 'false');
				LittledataPixel.sendEvent({
					event_name: 'adunblocked',
					event_destination: destination,
				});
			}
		})
		.catch(() => {
			if (adblocked !== 'true') {
				window.localStorage.setItem('colibrius_adblocked', 'true');
				LittledataPixel.sendEvent({
					event_name: 'adblocked',
					event_destination: destination,
				});
			}
		});
}
