export const LittledataScriptVersion = process.env.VERSION;
export const STAGING = 'staging';
export const STAGING_TRANSACTION_WATCHER = 'https://transactions-staging.littledata.io';
export const PRODUCTION_TRANSACTION_WATCHER = 'https://transactions.littledata.io';
export const CLIENT_ID_CUSTOMER_ID_ROUTE = '/v2/clientID-customerID/store';
export const shopifyPageTypes = {
	//https://shopify.dev/docs/themes/liquid/reference/objects/request#request-page_type
	product: 'product',
	article: 'article',
	blog: 'blog',
	cart: 'cart',
	collection: 'collection',
	search: 'search',
	thankYou: 'thank_you',
};
export const localStorageNames = {
	list_position: 'littledata_list_position',
	list_name: 'littledata_list_name',
};
export const AW_CONVERSION_REGEX = '^AW-[0-9]+(?:[/][a-zA-Z0-9_-]+)$';
